.rhap_container {
  background: black;
  border-style: solid;
  border-color: white;
  margin-bottom: 1%;
  border-radius: 20px;
}

.rhap_container:hover {
  border-color: #FF80B2;
  color: #FF80B2;
}

.rhap_main-controls-button svg {
  color: #FF80B2;
}

.rhap_progress-indicator {
  background-color: #FF80B2 !important;

}

.rhap_button-clear.rhap_volume-button {
  color: #FF80B2;
}

.rhap_volume-bar, .rhap_volume-indicator {
  color: #FF80B2;
}

.rhap_progress-section {
  color: #FF80B2;
}

.rhap_time{
     color: #FF80B2;
}

.rhap_header{
     color: white;
     font-weight: bolder;
}
